import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import { Modal } from '@material-ui/core';


const SalesDetails = () => {
    const {id} = useParams();
    let days={
        'mon':'Monday',
        'tue':'Tuesday',
        'wed':'Wednesday',
        'thu':'Thursday',
        'fri':'Friday',
        'sat':'Saturday',
        'sun':'Sunday'
    }

    function getDays(day){
        console.log(day);
        const date1 = new Date(day);
        const date2 = new Date();
        const diffTime = Math.round(Math.abs(date2 - date1)/(1000*60));
        const diffTime1=Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24)); 
         if(diffTime<60){
        return  Math.floor(diffTime)+" minutes ago"; 
        }
        else if(diffTime>60&&diffTime<1440){
        return  Math.floor(diffTime/60) +" hours ago";    
        }
        else if(diffDays>30)
        return  Math.floor(diffDays/30) +" months ago";
        return diffDays + " days ago";
    }

   
    function tConvert (time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
      }
    const [carData, setCarData] = React.useState(null);
    //    let carInfo;

    // console.log(id);
    const getData = async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/single-sell?id=`+id,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }                                                                                                          
                }
            );
            
            // console.log(res.data);
            return res.data.data;
    }
    useEffect(() => {
        // console.log(id);
        getData().then((data) => {
            // console.log(data);
            setCarData(data);
            //  console.log(carData);
        });
    }, [carData]);
    
    const handleCloseCar =() => {
        carClose(id).then((data) => {
            console.log(data);
        })
    }
    const handleStockCar = () => {
        carStock(id).then((data) => {
            console.log(data);
            getData().then((refreshedData) => {
                setCarData(refreshedData);
            });
        })
    }
    const carClose = async(id) => {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/update-car-sell?id=`+id);
        // console.log(res.data);
        return res.data.data;
    }

    const carStock = async(id) => {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/update-car-stock?id=`+id);
        return res.data.data;
    }

    const [imageModal, setImageModal] =useState(false);




  return ( <div>
          <section className="sale_details_wrapper mt-3 pt-3">
              <div className="row ">
                  <div className="container">
                      <div className="col-lg-12 col-sm-12">
                          <div className="sale_details_above_data">
                              <div className="row sale_details_divs_mobile">
                                  <div className="col-lg-1 sale_details_above_div">
                                      <img className='sale_detail_car_logo' alt="suz" />
                                  </div>
                                  <div className="col-lg-4 sale_details_above_div">
                                      <div className="sales_data_details ">
                                          <h2 className="text-left d-inline" style={{ fontWeight: 600, fontSize: "19px" }}>
                                              {carData ? carData.make.make:null} {carData ? carData.make.model:null}  {carData ? carData.make.year:null}  - <span> {carData ? carData.make.version:null} </span></h2>
                                          <div className="d-flex ">
                                              <p className="text-left pr-2 sales_data_city" style={{ fontWeight: 600 }}>
                                              {carData ?carData.registerCity:""}
                                              </p>
                                              <p className="text-left">{getDays(carData?carData.date:null)}</p>
                                            
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-2 sale_details_above_div flex-column " style={{ alignItems: "normal" }}>
                                      <h3>Color</h3>
                                      <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>
                                      {carData ? carData.exteriorColor:""}
                                      </p>
                                  </div>
                                  <div className="col-lg-2 sale_details_above_div flex-column" style={{ alignItems: "normal" }} >
                                      <h3>Mileage</h3>
                                  <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>{carData ?carData.milage:""} km</p>
                                  </div>
                                  <div className="col-lg-3 sale_details_above_div flex-column" style={{ alignItems: "normal" }}>
                                      <h3>Transmission</h3>
                                  <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>{carData ?carData.transmission:""}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row carousel_image_container" style={{ margin: "10px 120px" }}>
                  <div className="col-lg-8 sale_details_data pb-5" >
                      <div className="sale_details_image position-relative" >
                          {/* Start of slideshow container  */}
                          <div className=" sale_details_above_div flex-column position-absolute save_button_sale" >
                              <button>
                                  <div className='buy_details_save_button '>
                                      <img alt="..." />
                                  </div>
                              </button>
                          </div>
                          <div className=" flex-column position-absolute instant_button_sale" >
                              <div className='sale_details_instant_button '>
                              {carData ? carData.priority.split(' ').slice(0, 2).join(' '):""}
                              </div>
                          </div>
                          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                              <ol className="carousel-indicators">
                                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                              </ol>
                             
                              <div className="carousel-inner">
                                {
                                        carData ? carData.carImages.map((item, index) => {
                                            return ( <div className={index == 0 ? "carousel-item active": "carousel-item"}>
                                        <img src={carData?`${process.env.REACT_APP_API_URL}` + item :""} className="d-block w-100" alt="..." />

                                        {/* <Modal
                                            show={imageModal}
                                            onHide={() => setImageModal(false)}
                                            dialogClassName="modal-90w"
                                            aria-labelledby="example-custom-modal-styling-title"
                                        >
                                        hello
                                        </Modal> */}
                                    </div>


                                            )   
                                        }) : "" 
                                
                                }

                           



                              </div>
                          </div>
                          <ol className="carousel-indicators">
                          {
                                        carData ? carData.carImages.map((item, index) => {
                                            return ( <li data-target="#carouselExampleIndicators" data-slide-to={index} className={index == 0 ? "active": ""}>
                                  <img src={carData?`${process.env.REACT_APP_API_URL}` + item :""}  className="d-block w-100" alt="..." />
                              </li>
                                            )   
                                        }) : "" 
                                
                                }
                              
                              
                          </ol>
                      </div>

                      <div className="sale_details_others mt-3 py-4">
                          <div className='mb-4 mt-3 pb-3'>
                              <h1>Use My Location</h1>
                          <p>{carData ? carData.availability :null} </p>
                          </div>
                          <div className='d-flex'>
                              <div style={{ paddingRight: "135px" }}>
                                  <p>Available Time</p>
                                  {carData?carData.availableDays.map((item, index) => {
                                                return <h1 key={index}>
                                                        {tConvert( item.timeField.from)}-{tConvert(item.timeField.to)}
                                                </h1>
                                            }
                                    ):null}
                              </div>
                              <div >
                                  <p>Available Days</p>
                                  {carData?carData.availableDays.map((item, index) => {
                                                return <h1 key={index}>
                                                    <span>
                                                        {days[item.days[0]]}&nbsp;
                                                    </span>{item.days.length>1?

                                                    <span>
                                                        to {days[item.days[item.days.length - 1]]}
                                                    </span>:null}

                                                </h1>
                                            }
                                    ):null}
                              </div>
                          </div>

                      </div>
                      <div className='sale_detail_notes container' >
                          <div className="row">
                              <div className="col-sm-12" >
                                  <div>
                                      <h1 className='pb-3'>Notes</h1>
                                      <p>{carData ? carData.notes : null}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                      <div className="sale_details_side_data">
                          <div className="sale_details_side_div">
                              <div className="side_details_top_div">
                                  <div className='py-2'>
                                      <p
                                          style={{ fontSize: "16px", fontWeight: "500", letterSpacing: "0.34px", color: "#7F7F7F", paddingBottom: "15px" }}
                                      >Posted By</p>
                                  <h1>{carData ? carData.name : null}</h1>
                                  </div>
                                  <div className='d-flex py-2'>
                                      <img  alt="suz" className='mr-2 email_logo' />
                                  <p>{carData ? carData.email : null}</p>
                                  </div>
                                  <div className='d-flex pb-4'>
                                      <img  alt="suz" className='mr-2 contactNo_logo' />
                                  <p >+{carData ? carData.contactNo : null}</p>
                                  </div>
                              </div>

                              {/* Map area  */}
                              <div className="sale_details_side_map">
                                  <div className="side_map_div d-flex ">
                                      <div className='pr-3 pt-1'>
                                          <img className='map_logo' alt="suz" />
                                      </div>
                                      <div className='d-flex flex-column '>
                                          <div>
                                          <p style={{ fontWeight: 600, fontSize: "16px" }}>{carData ? carData.address : null} </p>
                                          </div>
                                          <div className='mt-3'>
                                              <a href='/' className='mt-3'>View Directions</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              {/* End of map div  */}

                              {/* Start of Social Div  */}
                              <div className="sale_details_side_social">
                                  <div className="request_number_div d-flex justify-content-between ">
                                      <div>
                                          <h1>Request Number</h1>
                                      </div>
                                      <div>
                                          RN123412354532
                                      </div>
                                  </div>
                                  <hr />

                                  <div className="details_social_icons d-flex flex-column align-content-center ">
                                      <div className='py-3 my-2'>
                                          <h1 className='text-center'>Share Now</h1>
                                      </div>
                                      <div className='social_logos d-flex align-items-center justify-content-around'>
                                          <img className='messenger_logo' alt='...' />
                                          <img className='facebook_logo' alt='...' />
                                          <img className='insta_logo' alt='...' />
                                          <img className='whatsapp_logo' alt='...' />
                                      </div>
                                  </div>
                              </div>
                              {/* End of Social Div  */}

                          </div>
                          <div className='sale_detail_close_button'>
                              <button className='close_button'
                                    onClick={handleCloseCar} disabled={carData?!carData.active:false}
                              >
                                  {carData?carData.active?"Close Request":"Closed":null}
                              </button>
                          </div>
                          <div className='sale_detail_close_button'>
                              <button className='close_button'
                                    onClick={handleStockCar} 
                                    disabled={carData ? !carData.active : true}
                              >
                                  {carData ? (!carData.in_stock ? "Add in Stock" : "Stocked") : null}
                              </button>
                          </div>
                      </div>
                  </div>
              </div>


          </section>
    </div>
  )
}

export default SalesDetails